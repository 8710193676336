import { Modal, ModalOptions } from "flowbite";

export const handleShowModal = async (
    e: any,
    modalName: string
  ) => {
    e.preventDefault();
    const modalEle = document.getElementById(modalName);
    const modalOptions: ModalOptions = {
        closable: false,
    }
    const modal = new Modal(modalEle, modalOptions);
    modal.show();
  };

export const handleHideModal = async (e: any, modalName: string) => {
    e.preventDefault();
    const modalEle = document.getElementById(modalName);
    if (modalEle) {
        const modal = new Modal(modalEle);
        if (modal) {
            modal.hide();
        }
    }
    const modalBackdrop = document.querySelector("div[modal-backdrop]");
    if (modalBackdrop && modalBackdrop instanceof HTMLElement) {
      modalBackdrop.remove();
    }
  };
