import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../axios/AxiosInstance";
import {
  Company,
  Clinic,
  Option,
  Comuser,
  VitaUser,
  QueryResponse,
} from "type";
import GenericSelect from "component/GenericSelect";
import Loading from "component/Loading";
import { Modal, initFlowbite } from "flowbite";
import "flowbite";
import { AuthContext } from "component/AuthWrapper";
import { Pagination } from "@mui/material";
import { toast } from "react-toastify";
import { handleHideModal, handleShowModal } from "util/Modal";

function SearchCompanyPage() {
  const { userInfo, setUserInfo } = useContext(AuthContext);
  const [page, setPage] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState<Company>();
  const [clinics, setClinics] = useState<Clinic[]>();
  const [comuserList, setComuserList] = useState<QueryResponse>();
  const [payload, setPayload] = useState<any>();
  const [action, setAction] = useState<string>();
  var vitaRoleIdList: (string | undefined)[] = [];
  var clinicIdList: (string | undefined)[] = [];
  const genericSelectRef = useRef<{
    clearSelection: () => void;
  } | null>(null);

  const handleClearSelection = () => {
    if (genericSelectRef.current) {
      genericSelectRef.current.clearSelection();
    }
  };

  useEffect(() => {
    initFlowbite();
  }, []);

  useEffect(() => {
    if (keyword.length == 0) {
      handleSearch();
    }
  }, [keyword]);

  useEffect(() => {
    fetchComuserList();
  }, [company, clinics, page]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    event.preventDefault();
    setPage(Math.max(0, value - 1));
  };

  const handleAddUserSubmit = async (e: any) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const firstName = data.get("firstName") as string;
    const lastName = data.get("lastName") as string;
    const email = data.get("email") as string;
    var id;
    if (!firstName) {
      toast.error("First name is required!");
      return;
    }
    if (!lastName) {
      toast.error("Last name is required!");
      return;
    }
    if (!email) {
      toast.error("Email is required!");
      return;
    }
    const password = generatePassword(8);
    try {
      handleHideModal(e, "createUserModal");
      const payload = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
        vitaRoleIdList: vitaRoleIdList,
        clinicIdList: clinicIdList,
        comId: company?.id,
      }
      id = toast.loading("Checking permission...")
      const isAllowed = (await axiosInstance.post(`/role-action/is-allowed-operate?action=CREATE_VITA_USER`)).data.data;
      toast.dismiss(id);
      if (!isAllowed) {
        await setAction("CREATE_VITA_USER");
        setPayload(btoa(JSON.stringify(payload)));
        handleShowModal(e, "approvalModal");
		await handleDownloadPassword(password, email);
        return;
      }
      id = toast.loading("Creating user...");
      const res = await axiosInstance.post("/panel/create-vita-user", payload);
      await handleDownloadPassword(password, email);
      toast.dismiss(id);
      toast.success("User created successfully!");
      initFlowbite();
      fetchComuserList();
    } catch (error: any) {
      toast.dismiss(id);
    } finally {
      initFlowbite();
    }
  };

  const handleCreateCompanySubmit = async (e: any) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const name = data.get("name") as string;
    const code = data.get("code") as string;
    var id;
    if (!name) {
      toast.error("Comapny name is required!");
      return;
    }
    if (!code) {
      toast.error("Company code is required!");
      return;
    }
    try {
      handleHideModal(e, "createCompanyModal");
      const payload = {
        name: name,
        code: code
      };
      id = toast.loading("Checking permission...")
      const isAllowed = (await axiosInstance.post(`/role-action/is-allowed-operate?action=CREATE_COMPANY`)).data.data;
      toast.dismiss(id);
      if (!isAllowed) {
        await setAction("CREATE_COMPANY");
        setPayload(btoa(JSON.stringify(payload)));
        handleShowModal(e, "approvalModal");
        return;
      }
      id = toast.loading("Creating company...");
      initFlowbite();
      const res = await axiosInstance.post("/panel/create-company", payload);
      toast.dismiss(id);
      toast.success("Company created successfully!");
    } catch (error: any) {
      toast.dismiss(id);
    } finally {
      initFlowbite();
    }
  };

  const handleApprovalSubmit = async (e: any) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const remark = data.get("remark") as string;
    var id;
    try {
      handleHideModal(e, "approvalModal");
      id = toast.loading("Creating approval request...");
      await axiosInstance.post("/approval/create", {
        action: action,
        remark: remark,
        payload: payload,
      });
      toast.dismiss(id);
      toast.success("Approval request created successfully!");
    } catch (error: any) {
      toast.dismiss(id);
    } finally {
      setPayload(undefined);
      initFlowbite();
    }
  }

  const handleDownloadPassword = (password: string, email: string) => {
    if (!password) {
      return
    }
    const blob = new Blob([password], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = email + '.txt';
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const handleCompanyFilter = async (option: Option) => {
    setLoading(true);
    const id = option.value?.toString();
    const res = await axiosInstance.get(`/company/${id}`);
    setCompany(res.data.data);
    setComuserList(undefined);
    setClinics(undefined);
    handleClearSelection();
  };

  const handleVitaRoleFilter = async (options: Option[]) => {
    vitaRoleIdList = options.map((option) => option.value?.toString());
  };

  const handleClinicFilter = async (options: Option[]) => {
    setLoading(true);
    const clinicIdList = options.map((option) => option.value?.toString());
    const res = await axiosInstance.post(`/clinic/`, {
      clinicIdList: clinicIdList,
    });
    setClinics(res.data.data);
    setComuserList(undefined);
    initFlowbite();
  };

  const handleClinicFilterInModal = async (options: Option[]) => {
    clinicIdList = options.map((option) => option.value?.toString());
  };

  const fetchComuserList = async () => {
    if (!company || !company.id) {
      setLoading(false);
      return;
    }
    setLoading(true);
    const res = await axiosInstance.post(`/comuser/`, {
      comId: company?.id,
      keyword: keyword && keyword.length > 0 ? keyword : undefined,
      clinicIdList:
        clinics && clinics.length > 0
          ? clinics?.map((clinic) => clinic.id)
          : [],
      page: page,
      limit: 10,
    });
    setComuserList(res.data.data);
    setLoading(false);
    initFlowbite();
  };

  const handleConnectCompany = async (e: any) => {
    e.preventDefault();
    console.log(userInfo?.vitaEmail);
    if (!userInfo?.vitaEmail) {
      toast.warn("Please set your Vita email in your profile first!");
      return;
    }
    try {
      await axiosInstance.post(`/panel/connect-company/${company?.id}`);
      toast.success("Company connected successfully!");
    } catch (error: any) {}
  };

  const handleSearch = async () => {
    fetchComuserList();
  };

  const ConfirmConnectModal = () => {
    return (
      <div
        id="confirmConnectModal"
        data-modal-backdrop="static"
        className="fixed top-0 left-0 right-0 z-50 hidden p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
      >
        <div className="relative w-full max-w-md max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <button
              onClick={(e) => {handleHideModal(e, "confirmConnectModal")}}
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
            <div className="p-6 text-center">
              <svg
                className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                Are you sure you want to connect this company? All activity
                after connect will be recorded for security purposes.
              </h3>
              <button
                type="button"
                className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                onClick={(e) => {
                  handleHideModal(e, "confirmConnectModal");
                  handleConnectCompany(e);
                }}
              >
                Yes, I'm sure
              </button>
              <button
              onClick={(e) => {handleHideModal(e, "confirmConnectModal")}}
                type="button"
                className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              >
                No, cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const CreateUserModal = () => {
    return (
      <div
        id="createUserModal"
        data-modal-backdrop="static"
        className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full z-10"
      >
        <div className="relative p-4 w-full max-w-2xl h-full md:h-auto">
          <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-700 sm:p-5">
            <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                Create User
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={(e) => {handleHideModal(e, "createUserModal")}}
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            <form onSubmit={handleAddUserSubmit}>
              <div className="grid gap-4 mb-4 sm:grid-cols-2">
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    First Name
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="First Name"
                  />
                </div>
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Last Name"
                  />
                </div>
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Email
                  </label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Email"
                  />
                </div>
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Will be generated automatically"
                    disabled
                  />
                </div>
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Clinic
                  </label>
                  <GenericSelect
                    type={"CLINIC"}
                    param={company?.id ? [company?.id] : undefined}
                    callback={(o: Option | Option[]) => {
                      handleClinicFilterInModal(o as Option[]);
                    }}
                  />
                </div>
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Role
                  </label>
                  <GenericSelect
                    type={"VITA_ROLE"}
                    callback={(o: Option | Option[]) => {
                      handleVitaRoleFilter(o as Option[]);
                    }}
                    param={company?.id ? [company?.id] : undefined}
                  />
                </div>
              </div>
              <button
                type="submit"
                className="flex flex-row justify-center items-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm py-2 px-4 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              >
                <svg
                  className="mr-1 -ml-1 w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                Create User
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  };

  const CreateCompanyModal = () => {
    return (
      <div
        id="createCompanyModal"
        data-modal-backdrop="static"
        className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full z-10"
      >
        <div className="relative p-4 w-full max-w-2xl h-full md:h-auto">
          <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-700 sm:p-5">
            <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                Create Company
              </h3>
              <button
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white disabled"
                onClick={(e) => {handleHideModal(e, "createCompanyModal")}}
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            <form onSubmit={handleCreateCompanySubmit}>
              <div className="grid gap-4 mb-4 sm:grid-cols-2">
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Company Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Company Name"
                  />
                </div>
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Company Code
                  </label>
                  <input
                    type="text"
                    name="code"
                    id="code"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Company Code"
                  />
                </div>
              </div>
              <button
                type="submit"
                className="flex flex-row justify-center items-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm py-2 px-4 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              >
                <svg
                  className="mr-1 -ml-1 w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                Create Company
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  };

  const ApprovalModal = () => {
    return (
      <div
        id="approvalModal"
        data-modal-backdrop="static"
        className="fixed top-0 left-0 right-0 z-50 hidden p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
      >
        <div className="relative w-full max-w-md max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={(e) => {handleHideModal(e, "approvalModal")}}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
            <div className="p-6 text-center">
              <svg
                className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                Are you sure you want to perform this action? A request will be sent to the administrator for approval.
              </h3>
              <form className="px-4 pb-6" onSubmit={handleApprovalSubmit}>
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white flex items-start justify-start">
                    Remark
                  </label>
                  <input
                    type="text"
                    name="remark"
                    id="remark"
                    className="w-64 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Remark"
                  />
                </div>
                <div className="flex flex-row pt-5 items-center justify-center">
                  <button
                    type="submit"
                    className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                  >
                    Yes, I'm sure
                  </button>
                  <button
                    onClick={(e) => {handleHideModal(e, "approvalModal")}}
                    type="button"
                    className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  >
                    No, cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const generatePassword = (length: number) => {
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let password = '';
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset.charAt(randomIndex);
    }
  
    return password;
  };

  return (
    <>
      <ConfirmConnectModal />
      <CreateUserModal />
      <CreateCompanyModal />
      <ApprovalModal />
      {/* <CreateClinicModal /> */}
      <div className="flex flex-row justify-between items-center w-full">
        <h2 className="mb-4 text-4xl tracking-tight font-bold text-gray-900 dark:text-white">
          Search Company
        </h2>
          <button
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm py-2 px-4 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={(e) => {handleShowModal(e, "createCompanyModal")}}
          >
            Create Company
          </button>
        
      </div>
      <div className="mb-8 flex flex-col justify-between gap-5">
        <GenericSelect
          type={"COMPANY"}
          callback={(o: Option | Option[]) => {
            handleCompanyFilter(o as Option);
          }}
        />
        {company && company.id && (
          <GenericSelect
            ref={genericSelectRef}
            type={"CLINIC"}
            param={[company.id]}
            callback={(o: Option | Option[]) => {
              handleClinicFilter(o as Option[]);
            }}
          />
        )}
      </div>
      {loading ? (
        <div className="flex items-center justify-center p-8 pt-80">
          <Loading />
        </div>
      ) : (
        <>
          {comuserList && (
            <section className="sm:p-5 antialiased">
              <div className="mx-auto max-w-screen-xl px-0 lg:px-0">
                <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
                  <div className="flex flex-row gap-5 p-5 items-center justify-start">
                    <h2 className="mb-4 text-4xl tracking-tight font-bold text-gray-900 dark:text-white">
                      {company?.code}
                    </h2>
                    <h2 className="mb-4 text-3xl tracking-tight font-bold text-gray-900 dark:text-white">
                      {company?.des}
                    </h2>
                  </div>
                  <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 px-4 pb-4 pt-0">
                    <div className="w-full md:w-1/2">
                      <form
                        className="flex items-center"
                        onSubmit={(e) => {
                          handleSearch();
                        }}
                        onChange={(e: any) => {
                          e.preventDefault();
                          setKeyword(e.target.value);
                        }}
                      >
                        <label className="sr-only">Search User/Email</label>
                        <div className="relative w-full">
                          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg
                              className="w-4 h-4 text-gray-500 dark:text-gray-400"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 20 20"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                              />
                            </svg>
                          </div>
                          <input
                            type="text"
                            id="search"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Search User/Email"
                            value={keyword}
                          />
                          <button
                            type="button"
                            className="absolute inset-y-0 right-0 flex items-center pr-3"
                            onClick={(e) => {
                              setKeyword("");
                            }}
                          >
                            <svg
                              aria-hidden="true"
                              className="w-5 h-5 text-gray-400 dark:text-gray-300 hover:text-gray-500 dark:hover:text-gray-400"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clip-rule="evenodd"
                              ></path>
                            </svg>
                          </button>
                        </div>
                        <button
                          type="submit"
                          className="p-2.5 ml-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                          <svg
                            className="w-4 h-4"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 20"
                          >
                            <path
                              stroke="currentColor"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                            />
                          </svg>
                          <span className="sr-only">Search</span>
                        </button>
                      </form>
                    </div>
                      <button
                        type="button"
                        onClick={(e) => {
                          handleShowModal(e, "createUserModal")
                        }
                      }
                        className="w-36 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm py-2 px-4 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                      >
                        Create User
                      </button>
                    <button
                      type="button"
                      onClick={(e) => {handleShowModal(e, "confirmConnectModal")}}
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm py-2 px-4 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                    >
                      Connect
                    </button>
                  </div>
                  <div className="overflow-x-auto">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          <th scope="col" className="px-4 py-4">
                            User
                          </th>
                          <th scope="col" className="px-4 py-3">
                            Email
                          </th>
                          <th scope="col" className="px-4 py-3">
                            Role
                          </th>
                          <th scope="col" className="px-4 py-3">
                            <span className="sr-only">Actions</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {comuserList?.objectList?.map((entry) => (
                          <tr className="border-b dark:border-gray-700">
                            <th
                              scope="row"
                              className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white truncate overflow-ellipsis"
                            >
                              {entry.vitaUserList.at(0)?.firstName +
                                " " +
                                entry.vitaUserList.at(0)?.lastName}
                            </th>
                            <td className="px-4 py-3">
                              {entry.vitaUserList.at(0)?.email}
                            </td>
                            <td className="px-4 py-3">
                              {entry.vitaRoleList.map(
                                (role: {
                                  des:
                                    | string
                                    | number
                                    | boolean
                                    | React.ReactElement<
                                        any,
                                        | string
                                        | React.JSXElementConstructor<any>
                                      >
                                    | Iterable<React.ReactNode>
                                    | React.ReactPortal
                                    | null
                                    | undefined;
                                }) => {
                                  return (
                                    <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400">
                                      {role.des}
                                    </span>
                                  );
                                }
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <nav
                    className="flex flex-col md:flex-row justify-center items-center md:items-center space-y-3 md:space-y-0 p-4"
                    aria-label="Table navigation"
                  >
                    <ul className="inline-flex items-stretch -space-x-px">
                      <div className="flex items-center justify-center p-8">
                        <Pagination
                          count={Math.ceil(
                            comuserList ? comuserList.count / 10 : 1
                          )}
                          page={page + 1}
                          onChange={handlePageChange}
                        />
                      </div>
                    </ul>
                  </nav>
                </div>
              </div>
            </section>
          )}
        </>
      )}
    </>
  );
}

export default SearchCompanyPage;
