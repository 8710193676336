import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Index from "page/Index";
import ThemeProvider from "@mui/system/ThemeProvider";
import { Box, createTheme } from "@mui/system";
import { Grid } from "@mui/material";
import axios from "axios";

const theme = createTheme();

function Home() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const handleLogin = (e: any) => {
    e.preventDefault();
    // Perform login validation here
    if (username === "admin" && password === "password") {
      // Redirect to the home page if login is successful
      navigate("/");
    } else {
      alert("Invalid username or password");
    }
  };

  const paymentHealthcheck = () => {
    axios.get("https://revamp.apricotdemo.com/api/vita-pay/stripe/get-payment-method")
  }

  return (
    <>
      <h2 className="mb-4 text-4xl tracking-tight font-bold text-gray-900 dark:text-white">
        Welcome to Vita Admin Portal!
      </h2>
      <p className="mb-4 font-medium">
        Use the left side bar to navigate to different features.
      </p>
      <p className="mb-4 font-medium">
        Great power comes with great responsibility, please use it wisely. v1.0.0
      </p>
      <button type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" onClick={paymentHealthcheck}>
        Vita Pay Healthcheck Button
        </button>
    </>
  );
}

export default Home;
