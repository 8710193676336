import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Login from "./page/Login";
import Register from "./page/Register";
import AuthWrapper from "component/AuthWrapper";
import Index from "page/Index";
import "App.css"
import "flowbite";
import { initFlowbite } from "flowbite";
import { ToastContainer } from "react-toastify";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import ForgetPasswordPage from "page/ForgetPassword";
function App() {
  useEffect(() => {
    initFlowbite();
  }, []);

  window.addEventListener('error', e => {
    if(e.message === 'ResizeObserver loop completed with undelivered notifications.' || 
        e.message === 'ResizeObserver loop limit exceeded') {
        e.stopImmediatePropagation();
    }
  })
  return (
    <Router>
      <AuthWrapper>
        <ToastContainer />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MainContent />
          </LocalizationProvider>
      </AuthWrapper>
    </Router>
  );
}

function MainContent() {
  const location = useLocation();
  const isLoginOrRegister = location.pathname === "/login" || location.pathname === "/register";

  return (
    <>
      {/* {!isLoginOrRegister && <Index />} */}
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot" element={<ForgetPasswordPage />} />
      </Routes>
    </>
  );
}

export default App;