import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../axios/AxiosInstance";
import {
  Company,
  Clinic,
  Option,
  Comuser,
  VitaUser,
  QueryResponse,
  Approval,
  RoleAction,
} from "type";
import GenericSelect from "component/GenericSelect";
import Loading from "component/Loading";
import { Modal, ModalOptions, initFlowbite } from "flowbite";
import "flowbite";
import { AuthContext } from "component/AuthWrapper";
import { Pagination } from "@mui/material";
import { toast } from "react-toastify";
import { handleHideModal } from "util/Modal";

function RoleActionPage() {
  const { userInfo, setUserInfo } = useContext(AuthContext);
  const [page, setPage] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const [comuserList, setComuserList] = useState<QueryResponse>();
  const [roleActionList, setRoleActionList] = useState<RoleAction[]>();
  var actionIdList: (string | undefined)[] = [];
  const [selectedRoleAction, setSelectedRoleAction] = useState<RoleAction>();

  useEffect(() => {
    initFlowbite();
  }, []);

  useEffect(() => {
    fetchRoleActionList();
  }, [page]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    event.preventDefault();
    setPage(Math.max(0, value - 1));
  };

  const fetchRoleActionList = async () => {
    try {
      const res = await axiosInstance.get(
        `/search/role-action?keyword=${keyword}&page=${page}&limit=10`
      );
      setRoleActionList(res.data.data);
    } catch (error: any) {
    } finally {
      initFlowbite();
    }
  };

  const handleShowModal = async (
    e: any,
    modalName: string,
    roleAction?: RoleAction
  ) => {
    e.preventDefault();
    await setSelectedRoleAction(roleAction);
    const modalEle = document.getElementById(modalName);
    const modalOptions: ModalOptions = {
      closable: false,
    };
    const modal = new Modal(modalEle, modalOptions);
    modal.show();
  };

  const handleEditRoleActionSubmit = async (e: any) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const name = data.get("name") as string;
    if (!name) {
      toast.error("Role name is required!");
      return;
    }
    if (actionIdList.length === 0) {
      toast.error("Action is required!");
      return;
    }
    try {
      handleHideModal(e, "editRoleActionModal");
      toast.loading("Updating role...");
      initFlowbite();
      const res = await axiosInstance.post("/role-action/update", {
        role: {
          id: selectedRoleAction?.id,
          name: name,
        },
        actionIdList: actionIdList,
      });
      await fetchRoleActionList();

      // fetch user info
      const userRes = await axiosInstance.get("/user/info");
      setUserInfo(userRes.data.data);

      toast.dismiss();
      toast.success("Role updated!");
    } catch (error: any) {
      toast.dismiss();
    } finally {
      initFlowbite();
      setSelectedRoleAction(undefined);
    }
  };

  const handleCreateRoleActionSubmit = async (e: any) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const name = data.get("name") as string;
    if (!name) {
      toast.error("Role name is required!");
      return;
    }
    if (actionIdList.length === 0) {
      toast.error("Action is required!");
      return;
    }
    try {
      handleHideModal(e, "createRoleActionModal");
      toast.loading("Creating role...");
      initFlowbite();
      const res = await axiosInstance.post("/role-action/create", {
        name: name,
        actionIdList: actionIdList,
      });
      await fetchRoleActionList();
      toast.dismiss();
      toast.success("Role created!");
    } catch (error: any) {
      toast.dismiss();
    } finally {
      initFlowbite();
      setSelectedRoleAction(undefined);
    }
  };

  const handleActionFilterInModal = async (options: Option[]) => {
    actionIdList = options.map((option) => option.value?.toString());
  };
  const handleDeleteRoleAction = async (e: any) => {
    e.preventDefault();
    try {
      handleHideModal(e, "confirmDeleteRoleActionModal");
      toast.loading("Deleting role...");
      initFlowbite();
      const res = await axiosInstance.post(
        `/role-action/delete/${selectedRoleAction?.id}`
      );
      await fetchRoleActionList();
      toast.dismiss();
      toast.success("Role deleted!");
    } catch (error: any) {
      toast.dismiss();
    } finally {
      initFlowbite();
      setSelectedRoleAction(undefined);
    }
  };

  const ConfirmDeleteRoleActionModal = () => {
    return (
      <div
        id="confirmDeleteRoleActionModal"
        data-modal-backdrop="static"
        className="fixed top-0 left-0 right-0 z-50 hidden p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
      >
        <div className="relative w-full max-w-md max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={(e) =>
                handleHideModal(e, "confirmDeleteRoleActionModal")
              }
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
            <div className="p-6 text-center">
              <svg
                className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                Are you sure you want to delete this role?
              </h3>
              <button
                data-modal-hide="confirmDeleteRoleActionModal"
                type="button"
                className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                onClick={(e) => {
                  handleDeleteRoleAction(e);
                }}
              >
                Yes, I'm sure
              </button>
              <button
                onClick={(e) =>
                  handleHideModal(e, "confirmDeleteRoleActionModal")
                }
                type="button"
                className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              >
                No, cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const ConfirmRestartServerModal = () => {
    return (
      <div
        id="confirmRestartServerModal"
        data-modal-backdrop="static"
        className="fixed top-0 left-0 right-0 z-50 hidden p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
      >
        <div className="relative w-full max-w-md max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={(e) => handleHideModal(e, "confirmRestartServerModal")}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
            <div className="p-6 text-center">
              <svg
                className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                Are you sure to restart server? All operation will be
                interrupted for few minutes.
              </h3>
              <button
                type="button"
                className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                onClick={(e) => {
                  handleRestartServer(e);
                }}
              >
                Yes, I'm sure
              </button>
              <button
                onClick={(e) => handleHideModal(e, "confirmRestartServerModal")}
                type="button"
                className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              >
                No, cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleRestartServer = async (e: any) => {
    e.preventDefault();
    var id;
    try {
      handleHideModal(e, "confirmRestartServerModal");
      id = toast.loading("Restarting server...");
      const res = await axiosInstance.post("/restart/");
      toast.dismiss(id);
      toast.success("Server restarted!");
    } catch (error: any) {
      toast.dismiss(id);
    } finally {
      initFlowbite();
    }
  };

  const EditRoleActionModal = () => {
    return (
      <div
        id="editRoleActionModal"
        data-modal-backdrop="static"
        className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full z-10"
      >
        <div className="relative p-4 w-full max-w-2xl h-full md:h-auto">
          <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-700 sm:p-5">
            <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                Edit role action
              </h3>
              <button
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white disabled"
                onClick={(e) => {
                  handleHideModal(e, "editRoleActionModal");
                }}
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            <form onSubmit={handleEditRoleActionSubmit}>
              <div className="grid gap-4 mb-4 sm:grid-cols-2">
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Role name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Role Name"
                    defaultValue={selectedRoleAction?.name ?? "ABC"}
                  />
                </div>
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Action
                  </label>
                  <GenericSelect
                    type={"ACTION"}
                    callback={(o: Option | Option[]) => {
                      handleActionFilterInModal(o as Option[]);
                    }}
                    param={selectedRoleAction?.actionList.split(",") ?? []}
                  />
                </div>
              </div>
              <div className="flex flex-row gap-4">
                <button
                  type="submit"
                  className="flex flex-row justify-center items-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm py-2 px-4 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                >
                  Edit Role
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  const CreateRoleActionModal = () => {
    return (
      <div
        id="createRoleActionModal"
        data-modal-backdrop="static"
        className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full z-10"
      >
        <div className="relative p-4 w-full max-w-2xl h-full md:h-auto">
          <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-700 sm:p-5">
            <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                Create Role
              </h3>
              <button
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white disabled"
                onClick={(e) => {
                  handleHideModal(e, "createRoleActionModal");
                }}
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            <form onSubmit={handleCreateRoleActionSubmit}>
              <div className="grid gap-4 mb-4 sm:grid-cols-2">
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Role name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Role Name"
                  />
                </div>
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Action
                  </label>
                  <GenericSelect
                    type={"ACTION"}
                    callback={(o: Option | Option[]) => {
                      handleActionFilterInModal(o as Option[]);
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-row gap-4">
                <button
                  type="submit"
                  className="flex flex-row justify-center items-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm py-2 px-4 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                >
                  Create Role
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <ConfirmDeleteRoleActionModal />
      <EditRoleActionModal />
      <CreateRoleActionModal />
      <ConfirmRestartServerModal />
      <div className="flex flex-row justify-between items-center w-full">
        <h2 className="mb-4 text-4xl tracking-tight font-bold text-gray-900 dark:text-white">
          Role Action
        </h2>
        <div className="flex flex-row gap-5">
          <button
            type="button"
            className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
            onClick={(e) => {
              handleShowModal(e, "confirmRestartServerModal");
            }}
          >
            Restart Server
          </button>
          <button
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm py-2 px-4 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={(e) => {
              handleShowModal(e, "createRoleActionModal");
            }}
          >
            Create Role
          </button>
        </div>
      </div>
      <h2 className="mb-4 text-xl tracking-tight font-normal text-gray-900 dark:text-white">
        You MUST restart server to apply changes performed in this page.
      </h2>
      {loading ? (
        <div className="flex items-center justify-center p-8 pt-80">
          <Loading />
        </div>
      ) : (
        <>
          {true && (
            <section className="sm:p-5 antialiased w-full">
              <div className="mx-auto px-0 lg:px-0">
                <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
                  <div className="overflow-x-auto">
                    <table className="text-sm text-left text-gray-500 dark:text-gray-400">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          <th scope="col" className="px-4 py-4">
                            Role
                          </th>
                          <th scope="col" className="px-4 py-3 w-1/2">
                            Action
                          </th>
                          <th scope="col" className="px-4 py-3 w-1/2">
                            Operation
                          </th>
                          <th scope="col" className="px-4 py-3 w-full"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {roleActionList?.map((entry) => (
                          <tr className="border-b dark:border-gray-700">
                            <th
                              scope="row"
                              className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white truncate overflow-ellipsis"
                            >
                              {entry.name}
                            </th>
                            <td className="px-4 py-3">
                              <div className="flex flex-wrap items-center justify-start gap-2">
                                {entry.actionList
                                  .split(",")
                                  .map((action: string, index: number) => (
                                    <span
                                      key={index}
                                      className={
                                        action.includes("READ")
                                          ? "bg-blue-400 text-white text-xs font-normal mr-2 px-2.5 py-0.5 rounded dark:bg-gray-600 dark:text-gray-300"
                                          : "bg-red-400 text-white text-xs font-normal mr-2 px-2.5 py-0.5 rounded dark:bg-gray-600 dark:text-gray-300"
                                      }
                                    >
                                      {action}
                                    </span>
                                  ))}
                              </div>
                            </td>
                            <td className="px-4 py-3">
                              {entry.name !== "SYSTEM_ADMIN" ? (
                                <div className="flex flex-row gap-2 justify-start gap-2">
                                  <button
                                    className="flex flex-row justify-center items-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm py-2 px-4 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                                    onClick={(e) =>
                                      handleShowModal(
                                        e,
                                        "editRoleActionModal",
                                        entry
                                      )
                                    }
                                  >
                                    Edit
                                  </button>
                                  <button
                                    className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center py-0.5 px-4 text-center mr-2"
                                    onClick={(e) => {
                                      handleShowModal(
                                        e,
                                        "confirmDeleteRoleActionModal",
                                        entry
                                      );
                                    }}
                                  >
                                    Delete
                                  </button>
                                </div>
                              ) : (
                                <div className="flex flex-row gap-2 justify-start gap-2">
                                  <button
                                    disabled
                                    className="text-gray-400 bg-gray-200 cursor-not-allowed font-medium rounded-lg text-sm py-2 px-4 dark:bg-gray-700 dark:text-gray-300 focus:outline-none"
                                    onClick={(e) =>
                                      handleShowModal(
                                        e,
                                        "editRoleActionModal",
                                        entry
                                      )
                                    }
                                  >
                                    Edit
                                  </button>
                                  <button
                                    disabled
                                    className="text-gray-400 bg-gray-200 cursor-not-allowed font-medium rounded-lg text-sm py-2 px-4 dark:bg-gray-700 dark:text-gray-300 focus:outline-none"
                                    onClick={(e) => {
                                      handleShowModal(
                                        e,
                                        "confirmDeleteRoleActionModal",
                                        entry
                                      );
                                    }}
                                  >
                                    Delete
                                  </button>
                                </div>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <nav
                    className="flex flex-col md:flex-row justify-center items-center md:items-center space-y-3 md:space-y-0 p-4"
                    aria-label="Table navigation"
                  >
                    <ul className="inline-flex items-stretch -space-x-px">
                      <div className="flex items-center justify-center p-8">
                        <Pagination
                          count={Math.ceil(
                            comuserList ? comuserList.count / 10 : 1
                          )}
                          page={page + 1}
                          onChange={handlePageChange}
                        />
                      </div>
                    </ul>
                  </nav>
                </div>
              </div>
            </section>
          )}
        </>
      )}
    </>
  );
}

export default RoleActionPage;
