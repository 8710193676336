import React, { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Paper,
  Box,
  Grid,
  Typography,
  ThemeProvider,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { axiosInstance } from "../axios/AxiosInstance";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "component/AuthWrapper";
import { GoogleLoginButton, MicrosoftLoginButton } from "react-social-login-buttons";
const theme = createTheme();

const LoginPage = () => {
  const location = useLocation();
  const { userInfo, setUserInfo } = useContext(AuthContext);
  useEffect(() => {
    if (userInfo) {
      navigate("/");
    }
  }, [userInfo]);

  const navigate = useNavigate();
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (data.get("email") === "") {
      toast.error("Email is required!");
      return;
    }
    if (data.get("password") === "") {
      toast.error("Password is required!");
      return;
    }
    axiosInstance
      .post("/user/login", {
        email: data.get("email"),
        password: data.get("password"),
      })
      .then((response) => {
        console.log(response.data.data);
        setUserInfo(response.data.data);
        navigate("/");
        toast.success("Login success!");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: "url(login_background.jpeg)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              component="form"
              noValidate
              onSubmit={(e) => handleSubmit(e)}
              sx={{ mt: 15 }}
            >
              <Box sx={{ mb: 3 }}>
                <Typography sx={{ fontSize: "2.5rem", fontWeight: "600" }}>
                  Welcome Back!
                </Typography>
                <Typography sx={{ fontSize: "1.1rem" }}>
                  Enter your credentials below to login your account.
                </Typography>
              </Box>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 3,
                  width: "100%",
                  height: "3rem",
                  borderRadius: "10px",
                }}
              >
                <Typography sx={{ fontSize: "1rem" }}>Log In</Typography>
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="/forgot" variant="body2">
                    <Typography sx={{ fontSize: "1.1rem" }}>
                      Forgot Password?
                    </Typography>
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="/register" variant="body2">
                    <Typography sx={{ fontSize: "1.1rem" }}>
                      Don't have an account? Register
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ mt: 3 }}>
              <GoogleLoginButton onClick={() => {
                window.location.assign(`${process.env.REACT_APP_API_URL}/aqua-uaa/oauth2/authorize/google?redirect_uri=${process.env.REACT_APP_CLIENT_URL}`)
                }
              }/>
              <MicrosoftLoginButton onClick={() => {
                window.location.assign(`${process.env.REACT_APP_API_URL}/aqua-uaa/oauth2/authorize/azure?redirect_uri=${process.env.REACT_APP_CLIENT_URL}`)
                }
              }/>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default LoginPage;
