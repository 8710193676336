import React, { useContext, useEffect, useState } from "react";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import { redirect, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../component/AuthWrapper";
import { axiosInstance } from "../axios/AxiosInstance";
import "flowbite";
import { initFlowbite } from "flowbite";
import Home from "./Home";
import AuditLogPage from "./AuditLog";
import SearchCompanyPage from "./SearchCompany";
import ProfilePage from "./Profile";
import ManageUsersPage from "./ManageUsers";
import ApprovalPage from "./Approval";
import RoleActionPage from "./RoleAction";
import ReportPage from "./Report";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { useCookies } from "react-cookie";

interface SideBarProps {}

function Index({}: SideBarProps) {
  const { userInfo, setUserInfo } = useContext(AuthContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [page, setPage] = useState("HOME");
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const handleVitaSSO = async (e: any) => {
    e.preventDefault();
    try {
      const clientId = "9c43c678-4dac-431d-b9cf-39b592b58094"
      const clientSecret = "80759f09-9abf-46f9-9476-5fdaa1257d0f"
      const data = new URLSearchParams();
      data.append("grant_type", "client_credentials");
      data.append("client_id", clientId);
      data.append("client_secret", clientSecret);

      const res = await axiosInstance.post("/aqua-uaa/oauth2/token", data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
      });
      
      const vitaRes = await axios.post("https://apricotdemo.com/auth/sso", res.data, {
        withCredentials: true,
      });
      window.open(`https://apricotdemo.com/login`, '_blank');
    }
    catch (error: any) {
      console.log(error)
    }
  };
  const handleLogin = (e: any) => {
    e.preventDefault();
    navigate("/login");
  };
  const handleLogout = async (e: any) => {
    e.preventDefault();
    await axiosInstance.post("/user/logout");
    setUserInfo(undefined);
  };

  useEffect(() => {
    initFlowbite();
  }, []);

  useEffect(() => {
    if (!userInfo) {
      setPage("HOME");
      setLoading(false);
    }
  }, [userInfo]);

  // useEffect(() => {
  //   fetchUserInfo();
  // }, [page]);

  const fetchUserInfo = async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get("/user/info");
      setUserInfo(res.data.data);
    } catch (error: any) {
      setUserInfo(undefined);
    }
    setLoading(false);
    initFlowbite();
  };

  const handleSetPage = (e: any, page: string) => {
    e.preventDefault();
    setLoading(true);
    // Timeout to allow loading to show
    const timer = setTimeout(() => {
      setPage(page);
      setLoading(false);
    }, 100);

    return () => clearTimeout(timer);
  };
  return (
    <>
      <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700t">
        <div className="px-3 py-3 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start">
              <button
                data-drawer-target="logo-sidebar"
                data-drawer-toggle="logo-sidebar"
                aria-controls="logo-sidebar"
                type="button"
                className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              >
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                    d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                  ></path>
                </svg>
              </button>
              <a
                className="flex ml-2 md:mr-24 cursor-pointer"
                onClick={(e) => {
                  handleSetPage(e, "HOME");
                }}
              >
                <img
                  src="logo512.png"
                  className="h-12 mr-3"
                  alt="Apricot Logo"
                />
              </a>
            </div>
            <div className="flex items-center">
              <div className="pt-3 flex items-center ml-3">
                {userInfo ? (
                  <>
                    <button
                      id="dropdownAvatarNameButton"
                      data-dropdown-toggle="dropdown-user"
                      className="flex items-center text-sm font-medium text-gray-900 rounded-full hover:text-blue-600 dark:hover:text-blue-500 md:mr-0 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-white"
                      type="button"
                    >
                      <span className="sr-only">Open user menu</span>
                      <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                        <span className="font-medium text-gray-600 dark:text-gray-300">
                          {userInfo.firstName.charAt(0) +
                            userInfo.lastName.charAt(0)}
                        </span>
                      </div>
                      <div className="p-2">
                        {userInfo.firstName + " " + userInfo.lastName}
                      </div>
                      <svg
                        className="w-2.5 h-2.5 ml-2.5"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m1 1 4 4 4-4"
                        />
                      </svg>
                    </button>
                    <div
                      id="dropdown-user"
                      className="z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600"
                    >
                      <div>
                        <div className="px-4 py-3 w-64" role="none">
                          <p
                            className="text-sm text-gray-900 dark:text-white"
                            role="none"
                          >
                            {userInfo?.firstName + " " + userInfo?.lastName}
                          </p>
                          <p
                            className="text-sm font-medium text-gray-900 truncate dark:text-gray-300"
                            role="none"
                          >
                            {userInfo?.email}
                          </p>
                        </div>
                        <ul className="py-1" role="none">
                          <li>
                            <a
                              href="#"
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                              role="menuitem"
                              onClick={(e) => handleLogout(e)}
                            >
                              Logout
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </>
                ) : (
                  <div>
                    <button
                      type="button"
                      onClick={(e) => {
                        handleVitaSSO(e);
                      }}
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                    >
                      Vita SSO
                    </button>
                    <button
                      type="button"
                      onClick={(e) => {
                        handleLogin(e);
                      }}
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                    >
                      Login
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>

      <aside
        id="logo-sidebar"
        className="fixed top-0 left-0 z-40 w-64 h-screen pt-24 transition-transform -translate-x-full bg-white border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700"
        aria-label="Sidebar"
      >
        <div className="h-full px-3 pb-4 overflow-y-auto bg-white dark:bg-gray-800r">
          <ul className="space-y-2 font-medium cursor-pointer">
            {userInfo?.actionList.some((a) => a.name == "READ_PROFILE") && (
              <li>
                <a
                  className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                  onClick={(e) => {
                    handleSetPage(e, "PROFILE");
                  }}
                >
                  <svg
                    className="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 16"
                  >
                    <path d="M18 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2ZM6.5 3a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5ZM3.014 13.021l.157-.625A3.427 3.427 0 0 1 6.5 9.571a3.426 3.426 0 0 1 3.322 2.805l.159.622-6.967.023ZM16 12h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Z" />
                  </svg>
                  <span className="flex-1 ml-3 whitespace-nowrap">Profile</span>
                </a>
              </li>
            )}
            {userInfo?.actionList.some((a) => a.name == "READ_AUDIT_LOG") && (
              <li>
                <a
                  className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                  onClick={(e) => {
                    handleSetPage(e, "AUDIT_LOG");
                  }}
                >
                  <svg
                    className="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 18"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m5 5 4 4-4 4m5 0h5M2 1h16a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1Z"
                    />
                  </svg>
                  <span className="ml-3">Audit Log</span>
                </a>
              </li>
            )}
            {userInfo?.actionList.some(
              (a) => a.name == "READ_SEARCH_COMPANY"
            ) && (
              <li>
                <a
                  className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                  onClick={(e) => {
                    handleSetPage(e, "SEARCH_COMPANY");
                  }}
                >
                  <svg
                    className="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 18 18"
                  >
                    <path d="M17 16h-1V2a1 1 0 1 0 0-2H2a1 1 0 0 0 0 2v14H1a1 1 0 0 0 0 2h16a1 1 0 0 0 0-2ZM5 4a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V4Zm0 5V8a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1Zm6 7H7v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3Zm2-7a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1Zm0-4a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1Z" />
                  </svg>
                  <span className="flex-1 ml-3 whitespace-nowrap">
                    Search Company
                  </span>
                </a>
              </li>
            )}
            {userInfo?.actionList.some(
              (a) => a.name == "READ_MANAGE_USERS"
            ) && (
              <li>
                <a
                  className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                  onClick={(e) => {
                    handleSetPage(e, "MANAGE_USERS");
                  }}
                >
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 19"
                  >
                    <path d="M7.324 9.917A2.479 2.479 0 0 1 7.99 7.7l.71-.71a2.484 2.484 0 0 1 2.222-.688 4.538 4.538 0 1 0-3.6 3.615h.002ZM7.99 18.3a2.5 2.5 0 0 1-.6-2.564A2.5 2.5 0 0 1 6 13.5v-1c.005-.544.19-1.072.526-1.5H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h7.687l-.697-.7ZM19.5 12h-1.12a4.441 4.441 0 0 0-.579-1.387l.8-.795a.5.5 0 0 0 0-.707l-.707-.707a.5.5 0 0 0-.707 0l-.795.8A4.443 4.443 0 0 0 15 8.62V7.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.12c-.492.113-.96.309-1.387.579l-.795-.795a.5.5 0 0 0-.707 0l-.707.707a.5.5 0 0 0 0 .707l.8.8c-.272.424-.47.891-.584 1.382H8.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1.12c.113.492.309.96.579 1.387l-.795.795a.5.5 0 0 0 0 .707l.707.707a.5.5 0 0 0 .707 0l.8-.8c.424.272.892.47 1.382.584v1.12a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1.12c.492-.113.96-.309 1.387-.579l.795.8a.5.5 0 0 0 .707 0l.707-.707a.5.5 0 0 0 0-.707l-.8-.795c.273-.427.47-.898.584-1.392h1.12a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5ZM14 15.5a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5Z" />
                  </svg>

                  <span className="flex-1 ml-3 whitespace-nowrap">
                    Manage Users
                  </span>
                </a>
              </li>
            )}
            {userInfo?.actionList.some((a) => a.name == "READ_APPROVAL") && (
              <li>
                <a
                  className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                  onClick={(e) => {
                    handleSetPage(e, "APPROVAL");
                  }}
                >
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 18 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 5h8m-1-3a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1m6 0v3H6V2m6 0h4a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h4m0 9.464 2.025 1.965L12 9.571"
                    />
                  </svg>

                  <span className="flex-1 ml-3 whitespace-nowrap">
                    Approval
                  </span>
                </a>
              </li>
            )}
            {userInfo?.actionList.some((a) => a.name == "READ_ROLE_ACTION") && (
              <li>
                <a
                  className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                  onClick={(e) => {
                    handleSetPage(e, "ROLE_ACTION");
                  }}
                >
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12.079 4.839a3 3 0 0 0-4.255.1M11 18h1.083A3.916 3.916 0 0 0 16 14.083V7A6 6 0 1 0 4 7v7m7 4v-1a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1Zm-7-4V8H3a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h1Zm12-6h1a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-1V8Z"
                    />
                  </svg>

                  <span className="flex-1 ml-3 whitespace-nowrap">
                    Role Action
                  </span>
                </a>
              </li>
            )}
            {userInfo?.actionList.some(
              (a) => a.name == "FULL_CONTROL_REPORT"
            ) && (
              <li>
                <a
                  className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                  onClick={(e) => {
                    handleSetPage(e, "REPORT");
                  }}
                >
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 6.03v13m0-13c-2.819-.831-4.715-1.076-8.029-1.023A.99.99 0 0 0 3 6v11c0 .563.466 1.014 1.03 1.007 3.122-.043 5.018.212 7.97 1.023m0-13c2.819-.831 4.715-1.076 8.029-1.023A.99.99 0 0 1 21 6v11c0 .563-.466 1.014-1.03 1.007-3.122-.043-5.018.212-7.97 1.023"
                    />
                  </svg>

                  <span className="flex-1 ml-3 whitespace-nowrap">Report</span>
                </a>
              </li>
            )}
          </ul>
        </div>
      </aside>
      <div className="p-8 pt-16 sm:ml-64">
        <section className="bg-white dark:bg-gray-900">
          <div className="py-8 px-4 lg:py-16 lg:px-6">
            <div className="max-w-screen-lg text-gray-500 sm:text-lg dark:text-gray-400"></div>
            {loading ? (
              <div className="flex items-center justify-center p-8 pt-80">
                <></>
              </div>
            ) : (
              <>
                {page == "HOME" && <Home />}
                {page == "PROFILE" && <ProfilePage />}
                {page == "AUDIT_LOG" && <AuditLogPage />}
                {page == "SEARCH_COMPANY" && <SearchCompanyPage />}
                {page == "MANAGE_USERS" && <ManageUsersPage />}
                {page == "APPROVAL" && <ApprovalPage />}
                {page == "ROLE_ACTION" && <RoleActionPage />}
                {page == "REPORT" && <ReportPage/>}
              </>
            )}
          </div>
        </section>
      </div>
    </>
  );
}

export default Index;