import { debounce } from "@mui/material";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import AsyncSelect from "react-select/async";
import { Option } from "../type";
import { axiosInstance } from "../axios/AxiosInstance";
import { MultiValue, SingleValue } from "react-select";
interface GenericSelectProps {
  type: string;
  param?: string[];
  callback: (value: Option | Option[]) => void;
}

const GenericSelect = forwardRef<{ clearSelection: () => void },GenericSelectProps>(({ type, param, callback}: GenericSelectProps, ref) => {
  let placeholder = "";
  let isMulti = false;
  let isSearchable = false;
  switch (type) {
    case "USER":
      placeholder = "Select User";
      isMulti = true;
      isSearchable = true;
      break;
    case "MANAGE_USERS":
      placeholder = "Select User";
      isMulti = false;
      isSearchable = true;
      break;
    case "LEVEL":
      placeholder = "Select Level";
      isMulti = true;
      isSearchable = false;
      break;
    case "ACTION":
      placeholder = "Select Action";
      isMulti = true;
      isSearchable = true;
      break;
    case "LIMIT":
      placeholder = "Limit";
      isMulti = false;
      isSearchable = false;
      break;
    case "COMPANY":
      placeholder = "Company";
      isMulti = false;
      isSearchable = true;
      break;
    case "CLINIC":
      placeholder = "Clinic";
      isMulti = true;
      isSearchable = true;
      break;
    case "VITA_ROLE":
      placeholder = "Vita Role";
      isMulti = true;
      isSearchable = true;
      break;
    case "USER_ROLE":
        placeholder = "User Role";
        isMulti = true;
        isSearchable = true;
        break;
    case "APPROVAL_STATUS":
      placeholder = "Approval Status";
      isMulti = true;
      isSearchable = false;
      break;
    case "FRONTEND_SECTION":
      placeholder = "Frontend Section";
      isMulti = true;
      isSearchable = true;
      break;
    default:
      break;
  }
  
  const [defaultOptions, setDefaultOptions] = useState<Option[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const handleClearSelection = () => {
    setSelectedOptions([]); // Clears the selected options
  };

  // Expose the handleClearSelection function to the parent component using useImperativeHandle
  useImperativeHandle(ref, () => ({
    clearSelection: handleClearSelection,
  }));
  const styles = {
    option: (provided: any) => ({
      ...provided,
      color: "#000000",
    }),
    control: (provided: any) => ({
      ...provided,
      "*": {
        boxShadow: "none !important",
      },
    }),
    container: (provided: any) => ({
      ...provided,
      flex: 1
    })

  }
  const fetchOptions = async (keyword: string) => {
    if (loading) return;
    setLoading(true);

    var res;
    try {
      switch (type) {
        case "USER":
          res = await axiosInstance.get(
            "/search/user?keyword=" + keyword + "&page=0&limit=20"
          );
          return res.data.data.map((user: any) => ({
            value: user.id,
            label: user.firstName + " " + user.lastName + " (" + user.email + ")",
          }));
        case "MANAGE_USERS":
            res = await axiosInstance.get(
              "/search/user?keyword=" + keyword + "&page=0&limit=20"
            );
            return res.data.data.map((user: any) => ({
              value: user.id,
              label: user.firstName + " " + user.lastName + " - " + user.email,
            }));
        case "COMPANY":
          res = await axiosInstance.get(
            "/search/company?keyword=" + keyword + "&page=0&limit=20"
          );
          return res.data.data.map((company: any) => ({
            value: company.id,
            label: company.code + " - " + company.des,
          }));
        case "CLINIC":
          if (!param) {
            return [];
          }
          res = await axiosInstance.get(
            `/search/clinic?comId=${param.at(0)}&keyword=${keyword}&page=0&limit=20`
          );
          return res.data.data.map((clinic: any) => ({
            value: clinic.id,
            label: clinic.code + " - " + clinic.des,
          }));
        case "LEVEL":
          return [
            {
              value: "INFO",
              label: "INFO",
            },
            {
              value: "WARN",
              label: "WARN",
            },
            {
              value: "FATAL",
              label: "FATAL",
            },
          ];
        case "ACTION":
           res = await axiosInstance.get(`/search/action?keyword=${keyword}&page=0&limit=20`);
           return res.data.data.map((action: any) => ({
             value: action.id,
             label: action.name,
           }));
        case "LIMIT":
          return [
            {
              value: "10",
              label: "10",
            },
            {
              value: "25",
              label: "25",
            },
            {
              value: "50",
              label: "50",
            },
          ];
        case "USER_ROLE":
          // Special handling for user role as it is a enum
          res = await axiosInstance.get(`/search/user-role`);
          return res.data.data.map((userRole: any) => ({
            value: userRole.id,
            label: userRole.name,
          }));
        case "VITA_ROLE":
          if (!param) {
            return [];
          }
          res = await axiosInstance.get(`/search/vita-role?comId=${param.at(0)}&keyword=${keyword}&page=0&limit=20`);
          return res.data.data.map((vitaRole: any) => ({
            value: vitaRole.id,
            label: vitaRole.des,
          }));
        case "APPROVAL_STATUS":
          return [
            {
              value: "APPROVED",
              label: "APPROVED",
            },
            {
              value: "PENDING",
              label: "PENDING",
            },
            {
              value: "REJECTED",
              label: "REJECTED",
            },
            {
              value: "CANCELLED",
              label: "CANCELLED",
            }
          ];
      }
    } catch (error) {
      setLoading(false);
      return [];
    } finally {
      setLoading(false);
    }
  };  

  const loadOptions = useCallback(
    debounce((inputValue: string, callback: (arg0: any) => any) => {
      fetchOptions(inputValue).then((options) => {
        callback(options)
      })
    }, 500),
    []
  );

  const handleInputChange = (inputValue: string) => {
    return inputValue;
  };
  const handleOptionSelect = async (
    selectedOption: SingleValue<Option> | MultiValue<Option>
  ) => {
    if (selectedOption != null) {
      setSelectedOptions(selectedOption as Option[]);
      callback(selectedOption as Option[]);
    }
    else {
      setSelectedOptions([]);
      callback([]);
    }
  };

  function isNotEmpty(value: SingleValue<Option> | MultiValue<Option>) {
    if (Array.isArray(value)) {
      return value.length > 0;
    } else if (typeof value === "object" && value !== null) {
      return Object.keys(value).length > 0;
    }
    return false;
  }

  const fetchDefaultOptions = async () => {
    setDefaultOptions(await fetchOptions(""));
  };

  useEffect(() => {
    fetchDefaultOptions();
    handlePresetOption();
  }, [param]);

  const handlePresetOption = async () => {
    if (type === "LIMIT") {
      setSelectedOptions([
        {
          value: "10",
          label: "10",
        },
      ]);
    }
    else if (type === "APPROVAL_STATUS") {
      setSelectedOptions([
        {
          value: 'PENDING',
          label: 'PENDING',
        }
    ])
    }

    if (param === undefined) return;

    if (type === "USER_ROLE" || type === "ACTION"){
      const val = await fetchOptions("").then((options) => {
        if (options) {
          return options.filter((option: any) => param.includes(option.label));
        }
      })
      console.log(val)
      if (val) {
        setSelectedOptions(val);
        callback(val);
      } 
    }
  }

  

  return (
    <AsyncSelect
      className="react-select-container w-128"
      classNamePrefix="react-select"
      maxMenuHeight={200}
      loadOptions={loadOptions}
      defaultOptions={defaultOptions}
      onInputChange={handleInputChange}
      value={isNotEmpty(selectedOptions) ? selectedOptions : null}
      onChange={(selectedOption) => handleOptionSelect(selectedOption)}
      menuShouldScrollIntoView={true}
      placeholder={placeholder}
      isMulti={isMulti}
      isSearchable={isSearchable}
      isLoading={loading}
      isClearable={true}
      styles={styles} 
  />

  );
})

export default GenericSelect;
