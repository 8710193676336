import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Index from "page/Index";
import ThemeProvider from "@mui/system/ThemeProvider";
import { Box, createTheme } from "@mui/system";
import { Grid, Pagination } from "@mui/material";
import { axiosInstance } from "../axios/AxiosInstance";
import { AuditLog, Filter, Option, QueryResponse } from "type";
import GenericSelect from "component/GenericSelect";
import Loading from "component/Loading";
import { initFlowbite } from "flowbite";
import { DateTimeField, DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import utcPlugin from "dayjs/plugin/utc";
dayjs.extend(utcPlugin);

function AuditLogPage() {
  const [tailing, setTailing] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState<QueryResponse>();
  const [startedAt, setStartedAt] = useState<Date | null>(null);
  const [endedAt, setEndedAt] = useState<Date | null>(null);
  const [filter, setFilter] = useState<Filter>({
    userIdList: [],
    levelList: [],
    actionList: [],
    startedAt: undefined,
    endedAt: undefined,
    page: 0,
    limit: 10,
  });

  const styles = {
    option: (provided: any) => ({
      ...provided,
      color: "#000000",
    }),
    control: (provided: any) => ({
      ...provided,
      "*": {
        boxShadow: "none !important",
      },
    }),
    container: (provided: any) => ({
      ...provided,
      flex: 1,
    }),
  };
  useEffect(() => {
    fetchAuditLogWithLoading();
  }, [filter]);

  useEffect(() => {
    if (tailing) {
      const interval = setInterval(() => {
        fetchAuditLog();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [tailing]);

  const fetchAuditLogWithLoading = async () => {
    setLoading(true);
    await fetchAuditLog();
    setLoading(false);
    initFlowbite();
  };

  const fetchAuditLog = async () => {
    const res = await axiosInstance.post("/audit-log/", filter);
    setData(res.data.data);
    initFlowbite();
  };

  const handleUserFilter = (options: Option[]) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      page: 0,
      userIdList: options.map((o) => o.value) as string[],
    }));
  };

  const handleLevelFilter = (options: Option[]) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      page: 0,
      levelList: options.map((o) => o.value) as string[],
    }));
  };

  const handleActionFilter = (options: Option[]) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      page: 0,
      actionList: options.map((o) => o.value) as string[],
    }));
  };

  const handleLimitFilter = (option: Option) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      page: 0,
      limit: parseInt(option.value as string),
    }));
  };

  const handleStartedAtChange = (date: Date | null) => {
    setStartedAt(date);
  };
  const handleEndedAtChange = (date: Date | null) => {
    setEndedAt(date);
  };
  const handleTimeFilter = () => {
    if (startedAt || endedAt) {
      const newFilter = {
        ...filter,
        page: 0,
      };

      if (startedAt) {
        newFilter.startedAt = dayjs.utc(startedAt).toDate();
      }

      if (endedAt) {
        newFilter.endedAt = dayjs.utc(endedAt).toDate();
      }

      setFilter(newFilter);
      initFlowbite();
    }
  };

  const handleClearClickStartedAt = () => {
    console.log(startedAt);
    setStartedAt(null);
  };

  const handleClearClickEndedAt = () => {
    setEndedAt(null);
  };

  const toggleTailing = () => {
    setTailing(!tailing);
  };

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  };

  const EntryComponent = ({ entry }: { entry: any }) => {
    useEffect(() => {
      initFlowbite();
    }, [entry]);
    return (
      <div className="text-sm font-normal text-gray-500 dark:text-gray-300">
        <span className="bg-gray-300 text-gray-800 text-xs font-normal mr-2 px-2.5 py-0.5 rounded dark:bg-gray-600 dark:text-gray-300">
          {entry.level}
        </span>
        <span className="bg-red-400 text-white text-xs font-normal mr-2 px-2.5 py-0.5 rounded dark:bg-gray-600 dark:text-gray-300">
          {entry.action}
        </span>
        <span
          data-popover-target={entry.id}
          className="font-semibold text-blue-600 dark:text-blue-500 cursor-pointer"
        >
          {entry.firstName + " " + entry.lastName}
        </span>{" "}
        <div
          data-popover
          id={entry.id}
          role="tooltip"
          className="absolute z-10 invisible inline-block w-48 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:bg-gray-800 dark:border-gray-600"
        >
          <div className="p-3">
            <p className="text-sm text-gray-900 dark:text-white">
              <a href="#">{entry.firstName + " " + entry.lastName}</a>
            </p>
            <p className="text-sm font-medium text-gray-900 truncate dark:text-gray-300">
              <a href="#" className="hover:underline">
                {entry.email}
              </a>
            </p>
            <p className="pt-2 text-sm text-gray-900 dark:text-white">
              <a>{entry.role}</a>
            </p>
          </div>
          <div data-popper-arrow></div>
        </div>
        <span>{entry.message}</span>
        {/* {entry.operatorEmail && (
          <>
            <span> - by </span>
            <span
              data-popover-target={entry.id + "-admin"}
              className="text-red-500 font-semibold cursor-pointer"
            >
              {entry.operatorFirstName + " " + entry.operatorLastName}
            </span>
          </>
        )}
        <div
          data-popover
          id={entry.id + "-admin"}
          role="tooltip"
          className="absolute z-10 invisible inline-block w-48 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:bg-gray-800 dark:border-gray-600"
        >
          <div className="p-3">
            <p className="text-sm text-gray-900 dark:text-white">
              <a>{entry.operatorFirstName + " " + entry.operatorLastName}</a>
            </p>
            <p className="text-sm font-medium text-gray-900 truncate dark:text-gray-300">
              <a className="hover:underline">{entry.operatorEmail}</a>
            </p>
            <p className="pt-2 text-sm text-gray-900 dark:text-white">
              <a>{formatRoleList(entry.operatorRole)}</a>
            </p>
          </div>
          <div data-popper-arrow></div>
        </div> */}
      </div>
    );
  };

  return (
    <>
      <h2 className="mb-4 text-4xl tracking-tight font-bold text-gray-900 dark:text-white">
        Audit Log
      </h2>
      <div className="flex flex-row gap-5 items-center flex-wrap">
        <div className="w-64">
          <div className="flex gap-4 items-center h-40 z-20">
            Entry / Page:
            <GenericSelect
              type={"LIMIT"}
              callback={(o: Option | Option[]) => {
                handleLimitFilter(o as Option);
              }}
            />
          </div>
        </div>
        <div className="w-64">
          <GenericSelect
            type={"LEVEL"}
            callback={(o: Option | Option[]) => {
              handleLevelFilter(o as Option[]);
            }}
          />
        </div>
        <div className="w-64">
          <GenericSelect
            type={"ACTION"}
            callback={(o: Option | Option[]) => {
              handleActionFilter(o as Option[]);
            }}
          />
        </div>
        <div className="w-64">
          <GenericSelect
            type={"USER"}
            callback={(o: Option | Option[]) => {
              handleUserFilter(o as Option[]);
            }}
          />
        </div>
        <button
          id="filterDropdownButton"
          data-dropdown-toggle="filterDropdown"
          className="w-full md:w-auto flex items-center justify-center py-2 px-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
          type="button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            className="h-4 w-4 mr-2 text-gray-400"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
              clip-rule="evenodd"
            />
          </svg>
          Time Filter
          <svg
            className="-mr-1 ml-1.5 w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path
              clip-rule="evenodd"
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            />
          </svg>
        </button>
        <div
          id="filterDropdown"
          className="z-10 hidden p-3 bg-white rounded-lg shadow dark:bg-gray-700 w-128"
        >
          <h6 className="mb-3 text-sm font-medium text-gray-900 dark:text-white">
            Select time period
          </h6>
          <ul
            className="space-y-2 text-sm w-full"
            aria-labelledby="filterDropdownButton"
          >
            <li className="flex items-center responsive gap-2">
              <DateTimeField
                label="Started at"
                sx={{
                  "*": {
                    boxShadow: "none !important",
                  },
                }}
                format="DD/MM/YYYY HH:mm:ss"
                onChange={handleStartedAtChange}
                value={startedAt}
              />
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={() => {
                  setStartedAt(null);
                }}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
              </button>
            </li>
            <li className="flex items-center responsive gap-2 responsive">
              <DateTimeField
                label="Ended at"
                sx={{
                  "*": {
                    boxShadow: "none !important",
                  },
                }}
                format="DD/MM/YYYY HH:mm:ss"
                onChange={handleEndedAtChange}
                value={endedAt}
              />
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={() => {
                  setEndedAt(null);
                }}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
              </button>
            </li>
            <li className="pt-3 flex items-center">
              <button
                type="button"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm py-2 px-4 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                onClick={handleTimeFilter}
              >
                Apply
              </button>
            </li>
          </ul>
        </div>
        {!tailing ? (
          <button
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm py-2 px-4 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={toggleTailing}
          >
            Enable tailing
          </button>
        ) : (
          <button
            type="button"
            className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm py-2 px-4 dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-800"
            onClick={toggleTailing}
          >
            Disable tailing
          </button>
        )}
      </div>
      {loading ? (
        <div className="flex items-center justify-center p-8 pt-80">
          <Loading />
        </div>
      ) : (
        <>
          <ol className="relative border-l border-gray-200 dark:border-gray-700 pt-6">
            {data?.objectList?.map((entry) => (
              <li className="mb-3 ml-6">
                <span className="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -left-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                  <div className="relative inline-flex items-center justify-center w-6 h-6 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                    <span className="text-xs font-normal text-gray-600 dark:text-gray-300">
                      {entry.firstName.charAt(0) + entry.lastName.charAt(0)}
                    </span>
                  </div>
                </span>
                <div className="items-center justify-between p-4 bg-white border border-gray-200 rounded-lg shadow-sm sm:flex dark:bg-gray-700 dark:border-gray-600">
                  <time className="mb-1 text-xs font-normal text-gray-400 sm:order-last sm:mb-0">
                    {dayjs
                      .utc(entry.createdAt)
                      .local()
                      .format("DD/MM/YYYY hh:mm:ss")}
                  </time>
                  <EntryComponent entry={entry} />
                </div>
              </li>
            ))}
          </ol>
          <div className="flex items-center justify-center p-8">
            <Pagination
              count={
                data?.count && filter?.limit
                  ? Math.ceil(data?.count / filter?.limit)
                  : 0
              }
              page={filter.page ? filter.page + 1 : 1}
              onChange={(event, value) => {
                setFilter((prevFilter) => ({
                  ...prevFilter,
                  page: value - 1,
                }));
              }}
            />
          </div>
        </>
      )}
    </>
  );
}

export default AuditLogPage;
