import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const getCookie = (name: string) => {
  const cookieString = document.cookie;
  const cookies = cookieString.split("; ");
  for (const cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split("=");
    if (cookieName === name) {
      return cookieValue;
    }
  }
  return null;
};

export function createAxiosInstance() {
  const axiosInstance = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  // Add a response interceptor to handle 401 errors
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (
        error.response &&
        error.response.status === 401 &&
        window.location.pathname !== "/login" &&
        window.location.pathname !== "/register"
      ) {
        // Clear token and redirect to login page
        console.log(window.location.pathname);
        toast.error("You are not authorized to access this page");
      } else if (error.response && error.response.status === 500) {
        toast.error(error.response.data.message);
      }
      return Promise.reject(error);
    }
  );
  return axiosInstance;
}

export const axiosInstance = createAxiosInstance();
