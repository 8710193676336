import React, { useContext, useEffect } from 'react';
import { Avatar, Button, CssBaseline, TextField, FormControlLabel, Checkbox, Link, Paper, Box, Grid, Typography, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { axiosInstance } from "../axios/AxiosInstance"
import { useNavigate } from 'react-router-dom';
import { Axios, AxiosError } from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from 'component/AuthWrapper';
const theme = createTheme();

const ForgetPasswordPage = () => {
  const navigate = useNavigate();
  const { userInfo, setUserInfo } = useContext(AuthContext);
  useEffect(() => {
    if(userInfo) {
      navigate('/');
    }
  }, [userInfo]);
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (!data.get('email')) {
      toast.error('Email is required!');
      return;
    }
    try {
      const res = await axiosInstance.post(`/user/reset?email=${data.get('email')}`)
      if (res.data) {
        toast.success('An email has been sent to your email address.');
        navigate('/login');
      } else {
        toast.error('Reset failed.');
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  return (
    <ThemeProvider theme={theme}> 
      <ToastContainer />
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: "url(login_background.jpeg)",
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 15}}>
              <Box sx={{ mb: 3 }}>
                <Typography sx={{fontSize: "2.5rem", fontWeight: "600"}}>
                Forgetting the password?
                </Typography>
                <Typography sx={{fontSize: "1.1rem"}}>
                Enter your email below to reset your password.
                </Typography>
              </Box>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 3, width: '100%', height: '3rem', borderRadius: "10px" }}>
              <Typography sx={{fontSize: "1rem"}}>Reset</Typography>
              </Button>
              <Grid container>
                <Grid item xs>
                </Grid>
                <Grid item>
                  <Link href="/login" variant="body2" >
                    <Typography sx={{fontSize: "1.1rem"}}>Remember the password? Login</Typography>
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default ForgetPasswordPage;