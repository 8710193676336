import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../axios/AxiosInstance";
import {
  Company,
  Clinic,
  Option,
  Comuser,
  VitaUser,
  QueryResponse,
  Approval,
} from "type";
import GenericSelect from "component/GenericSelect";
import Loading from "component/Loading";
import { Modal, initFlowbite } from "flowbite";
import "flowbite";
import { AuthContext } from "component/AuthWrapper";
import { Pagination } from "@mui/material";
import { toast } from "react-toastify";
import { handleHideModal, handleShowModal } from "util/Modal";

function ApprovalPage() {
  const { userInfo, setUserInfo } = useContext(AuthContext);
  const [page, setPage] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState<Company>();
  const [clinics, setClinics] = useState<Clinic[]>();
  const [comuserList, setComuserList] = useState<QueryResponse>();
  const [approvalList, setApprovalList] = useState<Approval[]>();
  const [approval, setApproval] = useState<Approval>();
  const [operation, setOperation] = useState<string>();
  const [approvalStatusList, setApprovalStatusList] = useState<string[]>([
    "PENDING",
  ]);

  useEffect(() => {
    fetchApprovalList();
  }, [approvalStatusList, page]);

  const handleApprovalStatusFilter = async (options: Option[]) => {
    const res = options.map((option: any) => option.value?.toString());
    console.log(res);
    setApprovalStatusList(res);
  };
  const handleApprove = async (e: any) => {
    e.preventDefault();
    var id;
    try {
      id = toast.loading("Executing operation...");
      await axiosInstance.post(`/approval/approve/${approval?.id}`);
      fetchApprovalList();
      toast.dismiss(id);
      toast.success("Operation executed successfully!");
    } catch (error: any) {
      toast.dismiss(id);
    } finally {
      initFlowbite();
    }
  };

  const handleReject = async (e: any, reason: string) => {
    e.preventDefault();
    var id;
    try {
      id = toast.loading("Rejecting approval request...");
      await axiosInstance.post(`/approval/reject`, {
        approvalEntryId: approval?.id,
        reason: reason,
      });
      fetchApprovalList();
      toast.dismiss(id);
      toast.success("Approval request rejected!");
    } catch (error: any) {
      toast.dismiss(id);
    } finally {
      initFlowbite();
    }
  };

  const handleCancel = async (e: any, reason: string) => {
    e.preventDefault();
    var id;
    try {
      id = toast.loading("Cancelling approval request...");
      await axiosInstance.post(`/approval/cancel`, {
        approvalEntryId: approval?.id,
        reason: reason,
      });
      fetchApprovalList();
      toast.dismiss(id);
      toast.success("Approval request cancelled!");
    } catch (error: any) {
      toast.dismiss(id);
    } finally {
      initFlowbite();
    }
  };

  const handleRejectCancelSubmit = async (e: any) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    // validate
    const reason = data.get("reason") as string;
    if (!reason) {
      toast.error("Reason is required!");
      return;
    }
    handleHideModal(e, "confirmModal");
    if (operation == "REJECT") {
      handleReject(e, reason);
    } else {
      handleCancel(e, reason);
    }
  };

  useEffect(() => {
    initFlowbite();
  }, []);

  useEffect(() => {
    fetchApprovalList();
  }, [company, clinics, page]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    event.preventDefault();
    setPage(Math.max(0, value - 1));
  };

  const fetchApprovalList = async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.post("/approval/", {
        approvalStatusList: approvalStatusList,
        page: page,
        limit: 20,
      });
      setApprovalList(res.data.data);
    } catch (error: any) {
    } finally {
      initFlowbite();
      setLoading(false);
    }
  };

  const ConfirmModal = () => {
    return (
      <div
        id="confirmModal"
        data-modal-backdrop="static"
        className="fixed top-0 left-0 right-0 z-50 hidden p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
      >
        <div className="relative w-full max-w-md max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <button
              onClick={(e) => {
                handleHideModal(e, "confirmModal");
              }}
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
            <div className="p-6 text-center">
              <svg
                className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                {operation == "APPROVE" && (
                  <>
                    Are you sure you want to approve this operation? The
                    operation will be executed immediately after approval.
                  </>
                )}
                {operation == "REJECT" && (
                  <>
                    Are you sure you want to reject this operation? You cannot
                    undo this action.
                  </>
                )}
                {operation == "CANCEL" && (
                  <>
                    Are you sure you want to cancel this operation? You cannot
                    undo this action.
                  </>
                )}
              </h3>
              {operation == "REJECT" || operation == "CANCEL" ? (
                <>
                  <form
                    className="px-4 pb-6"
                    onSubmit={handleRejectCancelSubmit}
                  >
                    <div className="pb-6">
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white flex items-start justify-start">
                        Reason
                      </label>
                      <input
                        type="text"
                        name="reason"
                        id="reason"
                        className="w-64 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Reason"
                      />
                    </div>
                    <button
                      type="submit"
                      className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                    >
                      Yes, I'm sure
                    </button>
                    <button
                      onClick={(e) => {
                        handleHideModal(e, "confirmModal");
                      }}
                      type="button"
                      className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                    >
                      No, cancel
                    </button>
                  </form>
                </>
              ) : (
                <>
                  <button
                    type={operation == "APPROVE" ? "button" : "submit"}
                    className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                    onClick={(e) => {
                      handleHideModal(e, "confirmModal");
                      operation == "APPROVE" && handleApprove(e);
                    }}
                  >
                    Yes, I'm sure
                  </button>
                  <button
                    onClick={(e) => {
                      handleHideModal(e, "confirmModal");
                    }}
                    type="button"
                    className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  >
                    No, cancel
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <ConfirmModal />
      <div className="flex flex-col justify-between items-start w-full">
        <h2 className="mb-4 text-4xl tracking-tight font-bold text-gray-900 dark:text-white">
          Approval
        </h2>
      </div>
      <div className="mb-8 flex flex-col justify-between gap-5">
        <GenericSelect
          type={"APPROVAL_STATUS"}
          callback={(o: Option | Option[]) => {
            handleApprovalStatusFilter(o as Option[]);
          }}
        />
      </div>
      {loading ? (
        <div className="flex items-center justify-center p-8 pt-80">
          <Loading />
        </div>
      ) : (
        <>
          {true && (
            <section className="sm:p-5 antialiased w-full">
              <div className="mx-auto px-0 lg:px-0">
                <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
                  <div className="overflow-x-auto">
                    <table className="text-sm text-left text-gray-500 dark:text-gray-400">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          <th scope="col" className="px-4 py-4">
                            Requester
                          </th>
                          <th scope="col" className="px-4 py-3">
                            Email
                          </th>
                          <th scope="col" className="px-4 py-3">
                            Role
                          </th>
                          <th scope="col" className="px-4 py-3">
                            Action
                          </th>
                          <th scope="col" className="px-4 py-3">
                            Remark
                          </th>
                          <th scope="col" className="px-4 py-3">
                            Payload
                          </th>
                          <th scope="col" className="px-4 py-3">
                            Reason
                          </th>
                          <th scope="col" className="px-4 py-3">
                            Status
                          </th>
                          <th scope="col" className="px-4 py-3">
                            Created At
                          </th>
                          <th scope="col" className="px-4 py-3">
                            Operation
                          </th>
                          <th scope="col" className="px-4 py-3 w-full"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {approvalList?.map((entry) => (
                          <tr className="border-b dark:border-gray-700">
                            <th
                              scope="row"
                              className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white truncate overflow-ellipsis"
                            >
                              {entry.firstName + " " + entry.lastName}
                            </th>
                            <td className="px-4 py-3">{entry?.email}</td>
                            <td className="px-4 py-3">
                              {entry.roleList
                                .split(",")
                                .map((role: string, index: number) => (
                                  <span
                                    key={index}
                                    className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400"
                                  >
                                    {role}
                                  </span>
                                ))}
                            </td>
                            <td className="px-4 py-3">
                              <span className="bg-red-400 text-white text-xs font-normal mr-2 px-2.5 py-0.5 rounded dark:bg-gray-600 dark:text-gray-300">
                                {entry?.action}
                              </span>
                            </td>
                            <td className="px-4 py-3 whitespace-normal">
                              {entry?.remark}
                            </td>
                            <td className="px-4 py-3 whitespace-normal">
                              {entry?.payload ? JSON.stringify(JSON.parse(atob(entry.payload), (key, value) => key === 'password' ? "****" : value)) : ""}
                            </td>

                            <td className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white truncate overflow-ellipsis">
                              {entry?.reason}
                            </td>
                            <td className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white truncate overflow-ellipsis">
                              {entry?.status}
                            </td>
                            <td className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white truncate overflow-ellipsis">
                              {entry?.createdAt}
                            </td>
                            <td className="px-4 py-3 flex gap-2">
                              {entry.status == "PENDING" &&
                                entry.allowedApprove && (
                                  <>
                                    <button
                                      className="flex flex-row justify-center items-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm py-2 px-4 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                                      onClick={async (e) => {
                                        await setApproval(entry);
                                        await setOperation("APPROVE");
                                        handleShowModal(e, "confirmModal");
                                      }}
                                    >
                                      Approve
                                    </button>
                                    <button
                                      className="flex flex-row justify-center items-center focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm py-2 px-4 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                                      onClick={async (e) => {
                                        await setApproval(entry);
                                        await setOperation("REJECT");
                                        handleShowModal(e, "confirmModal");
                                      }}
                                    >
                                      Reject
                                    </button>
                                  </>
                                )}
                              {entry.status == "PENDING" &&
                                !entry.allowedApprove &&
                                entry.email == userInfo?.email && (
                                  <>
                                    <button
                                      className="flex flex-row justify-center items-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm py-2 px-4 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                                      onClick={async (e) => {
                                        await setApproval(entry);
                                        await setOperation("CANCEL");
                                        handleShowModal(e, "confirmModal");
                                      }}
                                    >
                                      Cancel
                                    </button>
                                  </>
                                )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <nav
                    className="flex flex-col md:flex-row justify-center items-center md:items-center space-y-3 md:space-y-0 p-4"
                    aria-label="Table navigation"
                  >
                    <ul className="inline-flex items-stretch -space-x-px">
                      <div className="flex items-center justify-center p-8">
                        <Pagination
                          count={Math.ceil(
                            comuserList ? comuserList.count / 10 : 1
                          )}
                          page={page + 1}
                          onChange={handlePageChange}
                        />
                      </div>
                    </ul>
                  </nav>
                </div>
              </div>
            </section>
          )}
        </>
      )}
    </>
  );
}

export default ApprovalPage;
