import { axiosInstance } from '../axios/AxiosInstance';
import React, { createContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserInfo } from 'type';

interface AuthContextProps {
  userInfo: UserInfo | undefined;
  setUserInfo: React.Dispatch<React.SetStateAction<UserInfo | undefined>>;
}

export const AuthContext = createContext<AuthContextProps>({
  userInfo: undefined,
  setUserInfo: () => {},
});

interface AuthWrapperProps {
  children: React.ReactNode;
}

const AuthWrapper: React.FC<AuthWrapperProps> = ({ children }) => {
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const navigate = useNavigate();
  const location = useLocation();
  
  const fetchUserInfo = async () => {
    try {
      const res = await axiosInstance.get("/user/info");
      setUserInfo(res.data.data);
    } catch (error: any) {
      setUserInfo(undefined);
    }
  };
  // useEffect(() => {
  //   fetchUserInfo();
  // }, [location.pathname]);

  return (
    <AuthContext.Provider value={{ userInfo, setUserInfo }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthWrapper;
