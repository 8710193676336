import { AuthContext } from 'component/AuthWrapper';
import React, { useContext } from 'react';
import { axiosInstance } from "../axios/AxiosInstance";
import { toast } from 'react-toastify';
function ReportPage() {
    const generatePractitionerReport = async () => {
        const isAllowed = (await axiosInstance.post(`/role-action/is-allowed-operate?action=FULL_CONTROL_REPORT`)).data.data;
        if (isAllowed) {
            const id = toast.loading("Generating report...");
            try {
                const report = await axiosInstance.get("/report/practitioner");
                toast.dismiss(id);
                toast.success("Report generated successfully!");
                const url = window.URL.createObjectURL(new Blob([report.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'practitioner-report.csv');
                document.body.appendChild(link);
                link.click();
            }
            catch (error: any) {
                toast.dismiss(id);
                toast.error("Failed to generate report!");
            }
        }
    }

    return (
        <div className="flex flex-row justify-between items-center w-full">
        <h2 className="mb-4 text-4xl tracking-tight font-bold text-gray-900 dark:text-white">
          Report
        </h2>
        <div className="flex flex-row gap-5">
          <button
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm py-2 px-4 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={generatePractitionerReport}
          >
            Generate Practitioner Report
          </button>
        </div>
      </div>
    );
}

export default ReportPage;