import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Index from "page/Index";
import ThemeProvider from "@mui/system/ThemeProvider";
import { Box, createTheme } from "@mui/system";
import { Grid, Pagination } from "@mui/material";
import { axiosInstance } from "../axios/AxiosInstance";
import { AuditLog, Filter, Option, UserInfo, QueryResponse } from "type";
import GenericSelect from "component/GenericSelect";
import Loading from "component/Loading";
import { initFlowbite } from "flowbite";
import { AuthContext } from "component/AuthWrapper";
import { toast } from "react-toastify";
import TableSkeleton from "component/TableSkeleton";
const theme = createTheme();

function ManageUsersPage() {
  const { userInfo, setUserInfo } = useContext(AuthContext);
  const [user, setUser] = useState<UserInfo>();
  const [roleIdList, setRoleIdList] = useState<string[]>();
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    // validate
    const firstName = data.get("firstName") as string;
    const lastName = data.get("lastName") as string;
    const vitaEmail = data.get("vitaEmail") as string;
    if (!firstName) {
      toast.error("First name is required!");
      return;
    }
    if (!lastName) {
      toast.error("Last name is required!");
      return;
    }
    if (!roleIdList) {
      toast.error("Role is required!");
      return;
    }
    var id;
    try {
      id = toast.loading("Updating profile...");
      await axiosInstance.post("/manage-user/", {
      email: user?.email,
      firstName: firstName,
      lastName: lastName,
      vitaEmail: vitaEmail == "" ? undefined : vitaEmail,
      administrator: userInfo,
      roleIdList: roleIdList,
    });

      const userRes = await axiosInstance.get(`/user/${user?.id}`);
      setUser(userRes.data.data);
      toast.dismiss(id);
      toast.success("Update profile success!");
    }
    catch (error: any) {
      toast.dismiss(id)
    }
    finally {
      initFlowbite();
    }
  };

  const handleUserFilter = async (option: Option) => {
    setLoading(true);
    const res = await axiosInstance.get(`/user/${option.value}`);
    if (res.data) {
      setUser(res.data.data);
    }
    setLoading(false);
  };

  const handleUserRoleFilter = async (options: Option[]) => {
    setRoleIdList(
      options
        .map((o) => o.value)
        .filter((value): value is string => value !== undefined)
    );
  };

  return (
    <>
      <h2 className="mb-4 text-4xl tracking-tight font-bold text-gray-900 dark:text-white">
        Manage Users
      </h2>
      <GenericSelect
        type={"MANAGE_USERS"}
        callback={(o: Option | Option[]) => {
          handleUserFilter(o as Option);
        }}
      />
      {loading && (
        <div className="flex items-center justify-center p-8">
          <TableSkeleton />
        </div>
      )
      }
      {!loading && user && (
        <section className="bg-white dark:bg-gray-900 pt-5">
          <div className="max-w-full mx-auto">
            <form onSubmit={handleSubmit}>
              <div className="grid gap-4 mb-4 sm:grid-cols-2 sm:gap-6 sm:mb-5">
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Provider
                  </label>
                  <input
                    type="text"
                    name="provider"
                    id="provider"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    value={user?.provider}
                    placeholder="Provider"
                  />
                </div>
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Role
                  </label>
                  <GenericSelect
                    type={"USER_ROLE"}
                    callback={(o: Option | Option[]) => {
                      handleUserRoleFilter(o as Option[]);
                    }}
                    param={user?.roleList.map((r) => r.name)}
                  />
                </div>
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Email
                  </label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    value={user?.email}
                    placeholder="Email"
                  />
                </div>
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Vita Email
                  </label>
                  <input
                    type="text"
                    name="vitaEmail"
                    id="vitaEmail"
                    className="bg-white-50 border border-white-300 text-white-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-white-700 dark:border-white-600 dark:placeholder-white-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    defaultValue={user?.vitaEmail ? user?.vitaEmail : ""}
                    placeholder="Vita Email"
                  />
                </div>
                <div className="sm:col-span-2">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    First Name
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    className="bg-white-50 border border-white-300 text-white-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-white-700 dark:border-white-600 dark:placeholder-white-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    defaultValue={user?.firstName}
                    placeholder="First Name"
                  />
                </div>
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    className="bg-white-50 border border-white-300 text-white-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-white-700 dark:border-white-600 dark:placeholder-white-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    defaultValue={user?.lastName}
                    placeholder="Last Name"
                  />
                </div>
              </div>
              <div className="flex items-center space-x-4">
                <button
                  type="submit"
                  className="flex flex-row justify-center items-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm py-2 px-4 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                >
                  Update Profile
                </button>
              </div>
            </form>
          </div>
        </section>
      )}
    </>
  );
}

export default ManageUsersPage;
